<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <img :src="detailData.pictureUrl" class="top-img" />
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.expertName}}</label>
          </div>
          <div class="specialist_icon">{{expertLevelDic[detailData.expertLevel]}}</div>
          <div class="bank-name">领域：{{fieldDic[detailData.field]}}</div>
          <div class="bank-name">单位：{{detailData.unitName}}</div>
          <div class="condition">{{detailData.unitName}}{{expertLevelDic[detailData.expertLevel]}}</div>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">研究方向</div>
          <div class="content-text">{{detailData.researchDirection}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">研究成果</div>
          <div class="content-text">{{detailData.achievements}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">获奖情况</div>
          <div class="content-text">{{detailData.awards}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'perkDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      fieldDic: {
        1: "农林/牧渔/食品", 2: "交通/运输/包装", 3: "化工/冶金/生物", 4: "纺织/造纸", 5: "建筑/采矿/照明", 6: "机械/加工/设备", 7: "服饰/日用/家电", 8: '通信/电子/数码',
        9: '医疗/保健/美容',
        10: '能源/动力/电力',
        11: '法律领域',
      },
      expertLevelDic: { 1: "高级知识产权师" },
      id: null,
      detailData: {},
    }
  },
  computed: {
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.intellectualProperty.expertDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img {
    width: 71px;
  }
  .top-info {
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name {
      display: flex;
      align-items: center;
      flex-direction: row;
      .type {
        font-weight: 700;
      }
    }
    .specialist_icon {
      color: blue;
      font-size: 14px;
      background-color: #d6e3e5;
      margin-bottom: 10px;
    }
    .bank-name {
      font-size: 14px;
    }
    .condition {
      margin-top: 10px;
      font-size: 14px;
      .num {
        color: #f1af53;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.divider {
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content {
  margin: 20px 80px;
  .content-item {
    margin: 40px 0;
    .content-title {
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
